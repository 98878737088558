<template>
  <dx-util-popup
    ref="chatUserAssignmentPopupRef"
    :show-close-button="true"
    :drag-enabled="true"
    :close-on-outside-click="false"
    :show-title="true"
    :full-screen="false"
    title="Chat User Assignment"
    width="400px"
    height="auto"
    content-template="popup-content"
  >
    <dx-util-position at="center" my="center" />
    <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="cancelButtonOptions" />
    <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="saveButtonOptions" />
    <template #popup-content>
      <dx-util-validation-group :ref="chatAssigmentValidationRef">
        <dx-util-select-box
          v-model="assignmentForm.warehouseId"
          :data-source="warehouses"
          display-expr="text"
          value-expr="value"
          search-mode="contains"
          :show-data-before-search="false"
          :search-timeout="200"
          :search-enabled="true"
          search-expr="text"
          :min-search-length="0"
          label-mode="floating"
          label="Warehouses"
          class="my-1"
          @value-changed="selectWarehouse"
        >
          <dx-util-validator>
            <dx-util-required-rule message="Warehouse is a required field" />
          </dx-util-validator>
        </dx-util-select-box>
        <dx-util-select-box
          v-model="assignmentForm.userId"
          :data-source="users"
          display-expr="text"
          value-expr="value"
          search-mode="contains"
          :show-data-before-search="false"
          :search-timeout="200"
          :search-enabled="true"
          search-expr="text"
          :min-search-length="0"
          label-mode="floating"
          label="Users"
          class="my-1"
          @value-changed="selectUser"
        >
          <dx-util-validator>
            <dx-util-required-rule message="User is a required field" />
          </dx-util-validator>
        </dx-util-select-box>
        <dx-util-select-box
          v-model="assignmentForm.chatUserType"
          :data-source="chatUserTypes"
          display-expr="text"
          value-expr="value"
          label-mode="floating"
          label="Chat User Type"
          class="my-1"
          @value-changed="selectType"
        >
          <dx-util-validator>
            <dx-util-required-rule message="Chat user type is a required field" />
          </dx-util-validator>
        </dx-util-select-box>
      </dx-util-validation-group>
    </template>
  </dx-util-popup>
</template>

<script>
import chatService from '@/http/requests/chat/chatService'
import { getChatUserTypeList } from '@/enums/chatUserTypes.enum.js'

export default {
  props: {
    tenantId: {
      type: String,
      default: '',
      required: true,
    },
    editUser: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      assignmentForm: {
        id: null,
        warehouseId: null,
        customerCompanyId: null,
        userId: null,
        chatUserType: null,
      },
      warehouses: [],
      users: [],
      companies: [],
      chatAssigmentValidationRef: 'targetGroup1',
      chatUserTypes: getChatUserTypeList(),
    }
  },
  computed: {
    saveButtonOptions() {
      return {
        text: 'Save',
        type: 'success',
        useSubmitBehavior: true,
        onClick: e => {
          this.submit()
        },
      }
    },
    cancelButtonOptions() {
      return {
        text: 'Cancel',
        type: 'danger',
        onClick: () => {
          this.assignmentPopup.hide()
          this.$emit('on-emit-close-assignment-form')
        },
      }
    },
    assignmentPopup() {
      return this.$refs.chatUserAssignmentPopupRef.instance
    },
    validationGroup() {
      return this.$refs[this.chatAssigmentValidationRef].instance
    },
    companyId() {
      return this.$router.params.companyId
    },
  },
  watch: {
    editUser: {
      handler(payload) {
        if (payload.id !== null && payload.id !== undefined) {
          this.assignmentForm.id = payload.id
          this.assignmentForm.warehouseId = payload.warehouseId
          this.assignmentForm.userId = payload.userId
          this.assignmentForm.chatUserType = payload.chatUserType
          this.assignmentForm.customerCompanyId = payload.customerCompanyId
          if (payload.warehouseId > 0) {
            this.getUsers(payload.warehouseId)
          }
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.assignmentPopup.show()
    this.getWarehouses()
  },
  methods: {
    selectWarehouse(e) {
      this.assignmentForm.warehouseId = e.value
      this.getUsers(e.value)
    },
    async getWarehouses() {
      const result = await chatService.getTenantWarehouses()
      this.warehouses = result.map(item => ({
        value: item.id,
        text: item.name,
      }))
    },
    selectUser(e) {
      this.assignmentForm.userId = e.value
    },
    async getUsers(warehouseId) {
      const result = await chatService.findUsersByWarehouseId(warehouseId)
      this.users = result.map(item => ({
        value: item.id,
        text: `${item.login} - (${item.firstName} ${item.lastName})`,
      }))
    },
    selectType(e) {
      this.assignmentForm.chatUserType = e.value
    },
    async submit() {
      const response = await this.validationGroup.validate()
      this.assignmentForm.customerCompanyId = this.$route.params.companyId
      if (response.isValid) {
        if (this.assignmentForm.id !== null) {
          chatService.updateChatUser(this.assignmentForm).then(() => {
            this.$emit('on-emit-close-assignment-form')
            this.assignmentPopup.hide()
          })
        } else {
          chatService.assignChatUser(this.assignmentForm).then(() => {
            this.$emit('on-emit-close-assignment-form')
            this.assignmentPopup.hide()
          })
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
